.topo{
	box-shadow: 0 0 10px rgba(0,0,0,0.2);

	ul{
		list-style-type: none;
		padding-left: 0;
		margin-bottom: 0;
	}
	.redes-sociais{
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		a{
			width: 64px;
			height: 64px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 2px solid $vermelho;
			border-radius: 50%;
			color: $vermelho;
			font-size: 16px;
			transition: all 0.2s ease-out;

			&:hover{
				background-color: lighten($vermelho, 15%);
				color: #fff;
			}
		}
	}

	@include media-breakpoint-only(xl) {
		.main-navigation{
			padding: 15px 0;
			background-color: #fff;

			.meu-container{
				max-width: 1200px;
				width: 100%;
				margin-right: auto;
				margin-left: auto;
				padding-left: 15px;
				padding-right: 15px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.logo{
					margin-right: 80px;
				}
				.menu{
					display: flex;
					align-items: center;
					flex-grow: 1;
					justify-content: space-between;
					margin-right: 30px;

					.active a{
						color: $vermelho;
					}
					a{
						font-size: 15px;
						padding: 5px;
						transition: all 0.2s linear;
						&:hover{
							color: $vermelho;
						}
					}
				}
				.redes-sociais{
					a{
						margin-left: 30px;
					}
				}
			}
		}
		.mbl-controls{
			display: none;
		}
	}
	//ONLY XL

	@include media-breakpoint-down(lg) {
		.main-navigation{
			position: fixed;
			top: 0;
			left: -250px;
			width: 250px;
			height: 100%;
			z-index: 1000;
			background: #DD2476;
			background: -webkit-linear-gradient(to bottom, #DD2476, #FF512F);
			background: linear-gradient(to bottom, #DD2476, #FF512F);
			border-right: 1px solid #333;
			padding-bottom: 10px;
			overflow-y: auto;
			transition: left 0.4s ease-out;

			&.active{
				left: 0;
			}

			.logo{
				padding: 30px;
			}
			.menu{
				border-top: 1px solid $cinza;
				border-bottom: 1px solid $cinza;
				.active a{
					background-color: $cinza;
				}
				li + li{
					border-top: 1px solid $cinza;
				}
				a{
					display: block;
					padding: 10px;
					line-height: 1.4;
					color: #fff;

					&:hover{
						background-color: $cinza;
					}
				}
			}
			.redes-sociais{
				justify-content: center;
				margin-top: 30px;

				a{
					border-color: #fff;
					color: #fff;

					&:hover{
						background-color: $cinza;
					}
				}

				a + a{
					margin-left: 10px;
				}
			}
		}
		//.main-navigation

		.mbl-controls{
			position: relative;

			&:before{
				content: '';
				position: relative;
				display: block;
				width: 100%;
				height: 10px;
				background: #FC0E3A;
				background: -webkit-linear-gradient(to right, #FC0E3A, #DD2476, #FF512F);
				background: linear-gradient(to right, #FC0E3A, #DD2476, #FF512F);
				top: 0;
				left: 0;
			}
			.logo{
				padding: 10px 0;
				width: 150px;
			}

			.container{
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-wrap: wrap;
			}

			.btn-mbl{
				width: 56px;
				height: 56px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				color: $cinza;
				line-height: 1;
				font-size: 20px;

				.hamburguer{
					position: relative;
					display: block;
					background: $cinza;
					width: 29px;
					height: 3px;
					border-radius: 1px;
					-webkit-transition: .4s linear;
					-o-transition: .4s linear;
					transition: .4s linear;

					&:before,
					&:after{
						background: $cinza;
						content: '';
						display: block;
						width: 100%;
						height: 100%;
						position: absolute;
						-webkit-transition: .4s linear;
						-o-transition: .4s linear;
						transition: .4s linear;
					}
					&:before{
						top: -7px;
					}
					&:after{
						bottom: -7px;
					}
				}
				&.active{
					.hamburguer {
			            background-color: transparent;
			            &:before {
			                -webkit-transform: rotate(45deg);
			                    -ms-transform: rotate(45deg);
			                        transform: rotate(45deg);
			                top: 0;
			            }
			            &:after {
			                -webkit-transform: rotate(-45deg);
			                    -ms-transform: rotate(-45deg);
			                        transform: rotate(-45deg);
			                bottom: 0;
			            }
					}
				}
			}
		}
	}
	//DOWN LG
}
