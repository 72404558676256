// Margins
@include margin-variant(30);
@include margin-variant(40);
@include margin-variant(60);
@include margin-variant(70);

[data-owl]{

	&:not(.ready){
		display: none;
	}

	&.disabled{
		cursor: not-allowed;
		opacity: 0.6;
	}
}

$color-1: #D83135;
$color-2: #EC268F;
$color-3: #3E4095;
$color-4: #0098DA;
$color-5: #A8CF45;


#loading-page{
	position: fixed;
	z-index: 2000;
	width: 100%;
	height: 100%;
	background: #efefef;
	left:0;
	top:0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 30px;

	.container{
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
		overflow: hidden;
		animation-delay: 1s;
	}

	@keyframes scaleBall {
		0% {
			transform: scale(1);
		}
		50%,
		75% {
			transform: scale(2.5);
		}
		78%, 100% {
			opacity: 0;
		}
	}

	.ball{
		width: 20px;
	    height: 20px;
	    border-radius: 50%;
        margin: 7px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:before {
			content: '';
			width: 20px;
			height: 20px;
			border-radius: 50%;
			opacity: 0.7;
			animation: scaleBall 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
			transition: 0.5s all ease;
			transform: scale(1);
		}

        &.item-1{
        	background-color: $color-1;

        	&:before{
        		background-color: $color-1;
        		animation-delay: 200ms * 1;
        	}
        }
        &.item-2{
        	background-color: $color-2;

        	&:before{
        		background-color: $color-2;
        		animation-delay: 200ms * 2;
        	}
        }
        &.item-3{
        	background-color: $color-3;

        	&:before{
        		background-color: $color-3;
        		animation-delay: 200ms * 3;
        	}
        }
        &.item-4{
        	background-color: $color-4;

        	&:before{
        		background-color: $color-4;
        		animation-delay: 200ms * 4;
        	}
        }
        &.item-5{
        	background-color: $color-5;

        	&:before{
        		background-color: $color-5;
        		animation-delay: 200ms * 5;
        	}
        }
	}
}
