.box-qualidade{
	border-radius: 70px;
	overflow: hidden;
	padding: 30px;
	text-align: center;
	color: $cinza;
	line-height: 1.3;
	transition: all 0.4s ease-out;
	width: 100%;
	max-width: 360px;
	margin-left: auto;
	margin-right: auto;

	&:hover{
		color: #fff;

		.img{
			img{
				transform: scale(1);
			}
			&:after{
				width: 300%;
				height: 340%;
			}
		}

		.img{
			&::before{
				background-repeat: no-repeat;
				background-size: 100% 100%;
				background-position: 100% 100%;
				background-image: url('../images/bg-fundo-qualidade-2.png');
			}
		}
	}

	.img{
		text-align: center;
		width: 172px;
		height: 155px;
		min-width: 140px;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		z-index: 1;

		&:before,
		&:after{
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
		&:before{
			width: 100%;
			height: 100%;
			z-index: -1;
			background-repeat: no-repeat;
			background-size: 100% 100%;
			background-position: 100% 100%;
			background-image: url('../images/bg-fundo-qualidade.png');
			transition: all .4s linear;
		}
		&:after{
			width: 50%;
			height: 50%;
			z-index: -2;
			background-color: $vermelho;
			border-radius: 50%;
			transition: all 0.4s ease-out;
		}
		img{
			transform: scale(0.5);
			transition: all 0.4s ease-out;
		}
	}

	.nome{
		font-family: $mts-black;
		font-size: 20px;
		position: relative;
		z-index: 1;
		margin-top: 10px;
		margin-bottom: 5px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
	.texto{
		font-size: 14px;
		position: relative;
		z-index: 1;
	}

	@include media-breakpoint-only(xl) {
		.texto{
			height: 54px;
			@include line-clamp(3);
		}
	}
	@include media-breakpoint-down(lg) {
		.texto{
			height: 72px;
			@include line-clamp(4);
		}
	}
}
//box-qualidade

.box-solucoes{
	text-align: center;
	color: $cinza;
	transition: all 0.4s ease-out;
	overflow: hidden;
	width: 100%;
	max-width: 360px;
	margin-left: auto;
	margin-right: auto;
	line-height: 1.3;

	.img{
		width: 115px;
		min-width: 115px;
		margin-right: auto;
		margin-left: auto;
		position: relative;
	}

	.nome{
		font-family: $mts-black;
		font-size: 20px;
		position: relative;
		z-index: 1;
		margin-top: 15px;
		margin-bottom: 5px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		text-transform: uppercase;
	}
	.texto{
		font-size: 14px;
		position: relative;
		z-index: 1;
	}

	@include media-breakpoint-up(lg) {
		padding: 20px 30px 60px 30px;

		.img{
			&:before{
				content: '';
				position: relative;
				width: 2px;
				height: 25px;
				display: block;
				background-color: #000;
				margin: 0 auto 10px auto;
				opacity: 0;
				transition: all 0.4s ease-out;
			}
		}

		&:hover{
			padding-bottom: 10px;

			.img{
				&:before{
					height: 75px;
					opacity: 1;
				}
			}
		}
	}
	@include media-breakpoint-down(md) {
		padding: 15px;
	}

	@include media-breakpoint-only(xl) {
		.texto{
			height: 54px;
			@include line-clamp(3);
		}
	}
	@include media-breakpoint-down(lg) {
		.texto{
			height: 72px;
			@include line-clamp(4);
		}
	}
}
//.box-solucoes

.card-portfolio{
	display: block;
	width: 370px;
	max-width: 100%;
	margin-right: auto;
	margin-left: auto;
	position: relative;
	overflow: hidden;
	cursor: pointer;
	margin-top: 30px;

	.img{
		position: relative;

		&:before{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: $vermelho;
			z-index: 1;
			opacity: 0;
			transition: all 0.4s ease-out;
		}
	}

	.conteudo{
		text-align: center;
		position: absolute;
		z-index: 2;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -350%);
		color: #fff;
		width: 100%;
		padding: 10px 30px;
		font-size: 14px;
		line-height: 1.2;
		transition: all 0.4s ease-out;

		.nome{
			font-size: 20px;
			font-family: $mts-black;
			margin-bottom: 10px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}

	&:hover{
		.img{
			&:before{
				opacity: 1;
			}
		}
		.conteudo{
			transform: translate(-50%, -50%);
		}
	}

	@include media-breakpoint-only(xl) {
		.conteudo{
			.texto{
				height: 48px;
				@include line-clamp(3);
			}
		}
	}
	@include media-breakpoint-down(lg) {
		.conteudo{
			.texto{
				height: 64px;
				@include line-clamp(4);
			}
		}
	}
}
//.card-portfolio
