.btn-pill{
	border-radius: 2rem;
}

.btn-padrao{
	width: 250px;
	max-width: 100%;
	height: 70px;
	border-radius: 35px;
	background-color: $cinza;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	line-height: 1;
	font-family: $mts-black;
	font-size: 13px;
	transition: all 0.2s linear;
	border: none;
	outline: none;
	text-transform: uppercase;

	&:hover{
		background-color: $vermelho;
		color: #fff;
	}
}

#go-topo{
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 995;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transform: translateY(80px);
    background-color: $cinza;
    color: #fff;
    box-shadow: 0 0 6px rgba(#000, 0.6);
    transition: all .4s ease-out;
    font-size: 20px;

    &:hover{
    	background-color: #000;
    	color: #fff;
    }
}
#go-topo.active{
    transform: translateY(-50px);
}
