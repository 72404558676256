body{
	font-family: $pps-regular;
}
body,
html{
	width: 100%;
	min-height: 100vh;
}
img[style] {
	max-width: 100%;
	height: auto;
}
#app{
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	.wrapper{
		flex-grow: 1;
	}
}
textarea{
	resize: none;
}
figure{
	margin: 0;
}
h1, h2, h3, h4, h5, h6, p{
	margin-bottom: 0;
}
a{
	color: inherit;

	&:focus,
	&:hover{
		color: inherit;
		text-decoration: none;
	}
}
*[data-animate] {
	opacity: 0;
}
.animated[data-animate] {
	opacity: 1;
}
.bg-menu{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	display: none;
	background-color: rgba(0,0,0,0.6);
}
.title-1{
	color: $cinza;
	font-family: $mts-black;
	line-height: 1.2;

	span{
		color: $vermelho;
	}

	@include media-breakpoint-only(xl) {
		font-size: 34px;
	}
	@include media-breakpoint-between(sm, lg) {
		font-size: 28px;
	}
	@include media-breakpoint-only(xs) {
		font-size: 24px;
	}
}

.desc{
	color: $cinza;
	font-size: 15px;
	line-height: 1.4;

	img,
	iframe{
		max-width: 100%;
		height: auto;
	}

	&.editor{
		p + p {
			margin-top: 1em;
		}
	}
}

#modalPortfolio{
	text-align: center;
	font-size: 14px;
	line-height: 1.2;

	.modal-dialog{
		max-width: 630px;
	}
	.modal-header{
		padding: 5px 15px;
	}

	.modal-body{
		.img{
			text-align: center;

			.lazyimage{
				margin-left: auto;
				margin-right: auto;
			}
		}
	}

	.nome{
		font-size: 24px;
		font-family: $mts-black;
		text-transform: uppercase;
		margin: 10px 0;
	}
}

.btn__whatsapp{
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: 40px;
	background: #01AB4C;
	position: fixed;
	left: 15px;
	bottom: 15px;
	z-index: 999;

	&:hover{
		text-decoration: none;
		animation: rubberBand 1s linear;
		color: #fff;
	}
}
