.banners{
	width: 100%;
	max-width: 1920px;
	overflow: hidden;
	margin-left: auto;
	margin-right: auto;

	.carousel-indicators{
		right: auto;
		left: 0;

		li{
			opacity: 0.7;
			width: 18px;
			border: none;
			height: 18px;
			border-radius: 50%;
			transition: all 0.1s linear;

			&.active{
				opacity: 1;
			}
		}
	}

	@include media-breakpoint-only(xl) {
		.lazyimage{
			display: block;
			margin: auto;

			img{
				animation: scaleBanner 5s linear forwards;
			}
		}
		.carousel{
			min-width: 1920px;
			position: relative;
			left: 50%;
			transform: translateX(-50%);
			.carousel-inner{
				z-index: 2;
				.carousel-item{
					position: relative;
				}
			}
		}
		.carousel-indicators{
			margin-left: calc(calc(100% - 1170px)/2);
		}
	}
	//ONLY XL
	@include media-breakpoint-only(lg) {
		.carousel-indicators{
			margin-left: calc(calc(100% - 940px)/2);
		}
	}
	@include media-breakpoint-only(md) {
		.carousel-indicators{
			margin-left: calc(calc(100% - 690px)/2);
		}
	}
	@include media-breakpoint-down(sm) {
		.carousel-indicators{
			display: none;
		}
	}

	@keyframes scaleBanner{
		0%{
			transform: scale(1);
		}
		100%{
			transform: scale(1.1);
		}
	}
}
//.banners

.qualidades{
	padding: 40px 0;

	@include media-breakpoint-only(md) {
		.col-lg-4:last-child{
			margin-top: 30px;
		}
	}
	@include media-breakpoint-down(sm) {
		.col-lg-4 + .col-lg-4{
			margin-top: 30px;
		}
	}
}
//.qualidades

.quem-somos{
	.title-1{
		margin-bottom: 15px;
	}
	.desc{
		margin-bottom: 30px;
	}
	.botoes{
		display: flex;
		align-items: center;
		justify-content: center;

		@include media-breakpoint-up(sm) {
			a + a{
				margin-left: 20px;
			}
		}
		@include media-breakpoint-only(xs) {
			flex-direction: column;

			a + a{
				margin-top: 15px;
			}
		}
	}

	@include media-breakpoint-only(xl) {
		position: relative;
		z-index: 1;

		&:before{
			content: '';
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			left: 50%;
			width: 1920px;
			height: 607px;
			background-repeat: no-repeat;
			background-position: left center;
			background-image: url('../images/bg-quem-somos.png');
			z-index: -1;
		}

		.segura-content{
			width: calc(50% - calc(calc(100vw - 1200px)/2));
		}
	}
	@include media-breakpoint-only(lg) {
		.segura-content{
			width: calc(50% - calc(calc(100vw - 970px)/2));
		}
	}
	@include media-breakpoint-up(lg) {
		.row{
			justify-content: flex-end;

			.segura-imagem{
				display: flex;
				width: 50%;
				padding-left: 30px;
			}
		}
		.segura-content{
			display: flex;
			flex-direction: column;
			justify-content: center;
			.bloco{
				padding: 30px 15px;
			}
		}
	}
	@include media-breakpoint-down(md) {

		.title-1{
			text-align: center;
		}

		.segura-imagem,
		.segura-content{
			width: 100%;
			padding-left: 15px;
			padding-right: 15px;
			margin-right: auto;
			margin-left: auto;
		}
		.segura-imagem{
			margin-top: 30px;
			text-align: center;

			img{
				max-width: 100%;
				height: auto;
			}
		}
	}
	@include media-breakpoint-only(md) {
		.segura-imagem,
		.segura-content{
			max-width: 720px;
		}
	}
	@include media-breakpoint-only(sm) {
		.segura-imagem,
		.segura-content{
			max-width: 540px;
		}
	}
}
//.quem-somos

.solucoes{
	padding-top: 40px;
	position: relative;
	z-index: 2;

	.title-1{
		width: 900px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 40px;
		text-align: center;
	}

	@include media-breakpoint-up(lg) {
		padding-bottom: 40px;

		.col-lg-4 + .col-lg-4{
			border-left: 1px solid #CCCCCC;
		}
	}
}
//.solucoes

.servicos{

	.title-1 {
		font-size: 40px;

		@include media-breakpoint-down(sm){
			font-size: 25px;
		}

		span{
			font-size: 80px;

			@include media-breakpoint-down(sm){
				font-size: 40px;
			}
		}
	}

	.lista-servicos{
		list-style-type: none;
		padding-left: 0;
		margin-bottom: 0;

		.item{
			display: flex;
			align-items: center;
			font-size: 16px;
			color: $cinza;
			line-height: 1.1;
			padding: 10px 0;
			border-bottom: 1px solid #ccc;

			.check{
				display: block;
				width: 21px;
				height: 21px;
				background-repeat: no-repeat;
				background-position: center center;
				background-size: 100% 100%;
				background-image: url('../images/check.png');
				margin-right: 10px;
				min-width: 21px;
			}
		}
		@include media-breakpoint-only(xl) {
			.item{
				width: 264px;
				max-width: 100%;
			}
		}
		@include media-breakpoint-only(lg) {
			.item{
				width: 210px;
				max-width: 100%;
			}
		}
		@include media-breakpoint-only(md) {
			.item{
				width: 264px;
				max-width: 100%;
			}
		}
		@include media-breakpoint-only(sm) {
			.item{
				width: 250px;
				max-width: 100%;
			}
		}
		@include media-breakpoint-up(sm) {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.item:first-child,
			.item:nth-child(2){
				border-top: 1px solid #ccc;
			}
		}
	}

	.title-1{
		margin-bottom: 15px;
	}

	.btn-padrao{
		margin-top: 30px;
	}

	@include media-breakpoint-only(xl) {
		position: relative;
		z-index: 1;

		&:before{
			content: '';
			position: absolute;
			top: -260px;
			transform: translateX(-50%);
			left: 50%;
			width: 1920px;
			height: 607px;
			background-repeat: no-repeat;
			background-position: right center;
			background-image: url('../images/bg-servicos.png');
			z-index: -1;
		}

		.segura-content{
			width: calc(50% - calc(calc(100vw - 1200px)/2));
		}
	}
	@include media-breakpoint-only(lg) {
		.segura-content{
			width: calc(50% - calc(calc(100vw - 970px)/2));
		}
	}
	@include media-breakpoint-up(lg) {
		padding-top: 40px;

		.segura-imagem{
			display: flex;
			justify-content: flex-end;
			width: 50%;
			padding-right: 30px;
		}
		.segura-content{
			.bloco{
				padding: 30px 15px;
			}
		}
	}
	@include media-breakpoint-down(md) {
		padding-bottom: 40px;

		.title-1{
			text-align: center;
		}
		.segura-imagem,
		.segura-content{
			width: 100%;
			padding-left: 15px;
			padding-right: 15px;
			margin-right: auto;
			margin-left: auto;
		}
		.segura-imagem{
			margin-top: 30px;
			text-align: center;
			order: 2;

			img{
				max-width: 100%;
				height: auto;
			}
		}
		.btn-padrao{
			margin-right: auto;
			margin-left: auto;
		}
	}
	@include media-breakpoint-only(md) {
		.segura-imagem,
		.segura-content{
			max-width: 720px;
		}
	}
	@include media-breakpoint-only(sm) {
		.segura-imagem,
		.segura-content{
			max-width: 540px;
		}
	}
}
//.servicos

.portfolios{
	.campo-chamada{
		display: flex;
		flex-direction: column;
		justify-content: center;

		.bloco{
			text-align: center;
			padding-top: 30px;
			padding-bottom: 30px;

			.titulo{
				font-size: 16px;
				color: $cinza;
				line-height: 1.2;

				span{
					color: $vermelho;
					font-family: $mts-black;
					font-size: 40px;
				}
			}
			.desc{
				margin: 15px 0;
			}
			.btn-padrao{
				margin-right: auto;
				margin-left: auto;
			}
		}
	}

	@include media-breakpoint-only(xl) {
		position: relative;
		z-index: 1;

		&:before{
			content: '';
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			left: 50%;
			width: 1920px;
			height: 543px;
			background-repeat: no-repeat;
			background-position: left center;
			background-image: url('../images/bg-portfolio.png');
			z-index: -1;
		}

		.campo-chamada{
			.titulo-fundo{
				font-size: 80px;
				bottom: -55px;
			}
		}
	}
	@include media-breakpoint-only(lg) {
		.campo-chamada{
			.titulo-fundo{
				font-size: 60px;
				bottom: -35px;
			}
		}
	}
	@include media-breakpoint-up(lg) {
		padding-top: 30px;

		.col-lg-4:first-child{
			order: 2;
		}
		.col-lg-4:nth-child(2){
			order: 1;
		}
		.col-lg-4:nth-child(3){
			order: 3;
		}
		.col-lg-4:nth-child(4){
			order: 4;
		}
		.col-lg-4:nth-child(5){
			order: 5;
		}
		.col-lg-4:last-child{
			order: 6;
		}

		.campo-chamada{
			position: relative;
			z-index: 1;
			.titulo-fundo{
				color: rgba(220,220,220,0.25);
				font-family: $mts-black;
				line-height: 1;
				position: absolute;
				left: 0;
				transform: rotate(-90deg);
				transform-origin: left top 0;
				z-index: -1;
			}
		}
	}
	@include media-breakpoint-down(md) {
		.campo-chamada{
			.titulo-fundo{
				display: none;
			}
		}
	}
}
//.portfolios

.fale-conosco{

	#fale-conosco{
		padding-top: 30px;
	}

	.titulo{
		font-family: $mts-black;
		color: $cinza;
		line-height: 1;
		font-size: 40px;
	}
	.desc{
		margin: 10px 0 25px 0;
	}

	.form-group{
		margin-bottom: 20px;

		input.form-control{
			height: 38px;
		}

		.form-control{
			background-color: #eee;
			font-size: 14px;
			border: none;
			color: #000;
			border-radius: 19px;

			&::placeholder{
				color: #000;
			}

			&:focus{
				box-shadow: 0 0 0.25em rgba(0,0,0,0.6);
			}
		}
	}

	.custom-input-file{
		position: relative;
		label{
			position: absolute;
			background-color: #eee;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: #000;
			font-size: 14px;
			padding: 13px;
			height: 38px;
			border-radius: 19px;
			cursor: pointer;

			.output{
				width: 90%;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}

			.icone{
				z-index: 2;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 30px;
				height: 30px;
				.upload{
					width: 19px;
					height: 18px;
					background-repeat: no-repeat;
					background-size: 100% 100%;
					background-position: center center;
					background-image: url('../images/upload.png');
					display: block;
				}
			}
		}
	}

	.btn-padrao{
		margin-right: auto;
		margin-left: auto;
	}

	@include media-breakpoint-up(lg) {
		margin-top: -130px;
		padding-top: 244px;
		padding-bottom: 70px;
		position: relative;
		background-repeat: no-repeat;
		background-position: center bottom;
		background-image: url('../images/bg-fale-conosco.png');

		.titulo{
			font-size: 60px;
		}
	}

	@include media-breakpoint-down(md) {
		padding-bottom: 40px;
	}
}
