.internas{
	padding-bottom: 60px;

	.group-titulos{
		margin-bottom: 20px;

		.titulo-fundo{
			color: rgba(235,235,235,0.6);
			line-height: 1;
			font-family: $mts-black;
			text-transform: uppercase;
		}
	}

	.btn-padrao.btn-voltar{
		margin: 30px auto 0 auto;
	}

	.card-clientes{
		margin-top: 40px;
	}

	.lista-servicos{
		margin-top: 30px;
		list-style-type: none;
		padding-left: 0;
		margin-bottom: 0;

		.active .item{
			color: $vermelho;
		}

		.item{
			display: flex;
			align-items: center;
			font-size: 16px;
			color: $cinza;
			line-height: 1.1;
			padding: 10px 0;
			border-bottom: 1px solid #ccc;

			&:hover{
				color: $vermelho;
			}

			.check{
				display: block;
				width: 21px;
				height: 21px;
				background-repeat: no-repeat;
				background-position: center center;
				background-size: 100% 100%;
				background-image: url('../images/check.png');
				margin-right: 10px;
				min-width: 21px;
			}
		}
		@include media-breakpoint-only(xl) {
			li{
				width: 264px;
				max-width: 100%;
			}
		}
		@include media-breakpoint-only(lg) {
			li{
				width: 210px;
				max-width: 100%;
			}
		}
		@include media-breakpoint-only(md) {
			li{
				width: 264px;
				max-width: 100%;
			}
		}
		@include media-breakpoint-only(sm) {
			li{
				width: 250px;
				max-width: 100%;
			}
		}
		@include media-breakpoint-up(sm) {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			li:first-child,
			li:nth-child(2){
				border-top: 1px solid #ccc;
			}
		}
	}

	.bloco-servico-ajax{
		margin-top: 40px;

		.title-1{
			margin-bottom: 10px;
		}

		@include media-breakpoint-up(lg) {
			.title-1{
				text-align: right;
			}
		}
		@include media-breakpoint-down(md) {
			figure.col-lg-6{
				order: 2;
				margin-top: 30px;
			}
		}
	}

	@include media-breakpoint-only(xl) {
		padding-top: 100px;
		position: relative;
		z-index: 1;

		&:before,
		&:after{
			content: '';
			position: absolute;
			width: 1920px;
			transform: translateX(-50%);
			left: 50%;
			background-repeat: no-repeat;
			z-index: -1;
		}

		&:before{
			background-image: url('../images/bg-quem-somos.png');
			height: 607px;
			top: 150px;
			background-position: left center;
		}
		&:after{
			background-image: url('../images/bg-bottom-internas.png');
			height: 300px;
			bottom: 0px;
			background-position: right bottom;
		}

		.group-titulos{
			.titulo-fundo{
				font-size: 100px;
				margin-bottom: -50px;
			}
		}
	}
	@include media-breakpoint-only(lg) {
		padding-top: 90px;

		.group-titulos{
			.titulo-fundo{
				font-size: 90px;
				margin-bottom: -40px;
			}
		}
	}
	@include media-breakpoint-only(md) {
		padding-top: 80px;

		.group-titulos{
			.titulo-fundo{
				font-size: 80px;
				margin-bottom: -35px;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		padding-top: 60px;

		.group-titulos{
			.titulo-fundo{
				font-size: 40px;
				margin-bottom: -15px;
			}
		}
	}
}

.paginacao{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	list-style-type: none;
	padding-left: 0;
	margin-bottom: 0;
	margin-top: 40px;

	li + li{
		margin-left: 5px;
	}
	.active a{
		background-color: $vermelho;
	}
	a{
		width: 38px;
		height: 38px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: $mts-bold;
		font-size: 16px;
		line-height: 1;
		color: #fff;
		border-radius: 50%;
		background-color: #000;
		transition: all 0.2s linear;

		&:hover{
			background-color: $vermelho;
		}
	}
}
//.paginacao

.bloco-servicos{

	@include media-breakpoint-only(xl) {
		.segura-content{
			width: calc(50% - calc(calc(100vw - 1200px)/2));
		}
	}
	@include media-breakpoint-only(lg) {
		.segura-content{
			width: calc(50% - calc(calc(100vw - 970px)/2));
		}
	}
	@include media-breakpoint-up(lg) {
		.row{
			justify-content: flex-end;

			.segura-imagem{
				display: flex;
				width: 50%;
				padding-left: 30px;
			}
		}
	}
	@include media-breakpoint-down(md) {
		.segura-content{
			width: 100%;
			padding-left: 15px;
			padding-right: 15px;
			margin-right: auto;
			margin-left: auto;
		}
		.segura-imagem{
			display: none;
		}
	}
	@include media-breakpoint-only(md) {
		.segura-content{
			max-width: 720px;
		}
	}
	@include media-breakpoint-only(sm) {
		.segura-content{
			max-width: 540px;
		}
	}
}
//.bloco-servicos

.mosaico-portfolio{
	.mosaico-column,
	.mosaico-item{
		padding: 5px;

		@include media-breakpoint-up(lg) {
			width: calc(100% / 4);
		}
		@include media-breakpoint-between(sm, md) {
			width: calc(100% / 3);
		}
		@include media-breakpoint-only(xs) {
			width: calc(100% / 2);
		}
	}
	.mosaico-item{
		cursor: pointer;

		a{
			display: block;
			position: relative;
			z-index: 2;
		}

		.img{
			position: relative;

			&:before{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
				opacity: 0;
				background-color: rgba(252, 14, 58, 0.8);
				transition: all 0.4s ease-out;
			}
		}

		&:hover{
			.img{
				&:before{
					opacity: 1;
				}
			}
		}
	}
}
//.mosaico-portfolio
