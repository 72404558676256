.rodape{
	position: relative;
	z-index: 2;

	.content{
		padding: 40px 0;
		background-color: #eee;
		font-family: $mts-regular;
		color: $cinza;
		font-size: 14px;
		line-height: 1.4;

		.menu{
			list-style-type: none;
			padding-left: 0;
			margin-bottom: 0;
			text-transform: uppercase;

			.active a{
				color: #a8cf45;
			}

			li + li{
				margin-top: 5px;
			}

			a{
				&:hover{
					color: #a8cf45;
				}
			}
		}

		.titulo{
			font-size: 18px;
			line-height: 1.2;
			margin-bottom: 15px;

			span{
				display: block;
				font-family: $mts-black;
				color: #a8cf45;
			}

		}

		.box{
			margin-bottom: 15px;
			display: flex;

			.fas{
				min-width: 20px;
				margin-right: 5px;
			}
			.body{
				font-size: 14px;

				a + a{
					margin-left: 5px;
					padding-left: 5px;
					border-left: 1px solid #373435;
				}
			}
		}

		.redes-sociais{
			display: flex;
			align-items: center;
			a + a{
				margin-left: 10px;
			}
			a{
				width: 30px;
				height: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				border: 1px solid #373435;
				color: #373435;
				transition: all 0.2s linear;

				&:hover{
					background-color: #373435;
					color: #fff;
				}
			}
		}

		#mapa-rodape{
			padding-bottom: percentage(210/270);
		}

		@include media-breakpoint-down(md) {
			text-align: center;

			.box{
				flex-direction: column;
				text-align: center;
				justify-content: center;
				.fas{
					margin-right: 0;
					margin-bottom: 5px;
				}
			}
			.redes-sociais{
				justify-content: center;
				margin-bottom: 20px;
			}
			.titulo{
				margin-top: 20px;
			}
		}
	}
	//content

	.autorais{
		background-color: #a8cf45;
		font-size: 12px;
		color: #fff;
		line-height: 1;
		padding: 10px 0;

		.container{
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
			text-align: center;

			p{
				margin: 5px 5px 5px 0;
			}

			a{
				display: inline-flex;
				align-items: center;
				justify-content: center;

				img{
					margin-left: 5px;
				}
			}
		}
	}
	//.autorais
}
